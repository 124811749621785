import React, { useState } from 'react';
import CustomPassword from '../../components/input/custom/password';
import { CustomAlertTool } from '../../components/toolbar';
import {editPassword} from '../../api/user';

const Profile = () => {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState({});


  const onEditPassword = (record) => {
    editPassword({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError('success');
        setVisible(true);
      } else {
        setError('error');
        setVisible(true);
      }
    })
  }

  return (
    <>
      <CustomAlertTool visible={visible} setVisible={setVisible} onError={error} />
      <CustomPassword Text='修改密码' onOk={onEditPassword} title='New Password' />
    </>
  )
};

export default Profile;