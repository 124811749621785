import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { CustomConfirmButton, CustomAlertTool } from '../../components/toolbar';
import { reqServerInfo, editServerInfo } from "../../api/user";
import { getUserInfo } from "../../store/actions";
import "./index.css";

const fonticonList = {
  "United States": { country: "美国", className: "fonticon_us" },
  "United Kingdom": { country: "英国", className: "fonticon_gb" },
  "Japan": { country: "日本", className: "fonticon_jp" },
  "Korea": { country: "韩国", className: "fonticon_sk" },
  "Singapore": { country: "新加坡", className: "fonticon_sg" },
  "France": { country: "法国", className: "fonticon_fr" },
  "Germany": { country: "德国", className: "fonticon_de" },
  "Sweden": { country: "瑞典", className: "fonticon_se" },
};

const RadioGroup = ({ id, name, title, country, city, value, onChange }) => {
  return (
    <>
      <input
        type="radio"
        className="custom_radio_checkbox"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
      />
      <label htmlFor={id} className="custom_radio_checkbox_label">
        <span
          className={`custom_radio_checkbox_icon ${fonticonList?.[country]?.className}`}
        ></span>
        <span className="custom_radio_checkbox_checkmark">
          <i className="fa fa-check"></i>
        </span>
        <span className="custom_radio_checkbox_line1">{title}</span>
        <span className="custom_radio_checkbox_line2">{`${country} ${city}`}</span>
      </label>
    </>
  );
};

const RadioFormGroup = ({ dataSource, name, onChange }) => {
  return (
    <div>
      {dataSource?.map((item, index) => {
        return (
          <RadioGroup
            id={item?.id}
            key={index}
            name={name}
            country={item?.country}
            title={item?.name}
            city={item?.city}
            value={item?.id}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
};

const Server = ({ user, token, getUserInfo }) => {
  const [dataSource, setDataSource] = useState([]);
  const [filteredVal, setFilteredVal] = useState([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [visible, setVisible] = useState(false);

  const handleChange = (event) => {
    const idx = dataSource?.findIndex((element)=>element?.id === event.target.value);
    setValue(dataSource?.[idx]);
  };

  const filtered = (data, field) => {
    const result = {};
    const temp = [];
    data?.forEach((item) => {
        
      if (!result[item?.[field]]) {
        result[item?.[field]] = [];
      }
      result[item?.[field]].push(item);
    });
    for (let i in result) {
      temp.push(result[i]);
    }
    setFilteredVal(temp);
  };

  const fetchData = () => {
    reqServerInfo().then((response) => {
      const { code } = response;
      if (code === 200) {
        const { data } = response;
        setDataSource(data);
        filtered(data, "country");
      }
    });
  };

  const submitChange = () =>{
    if (value === "") {
      return;
    }
    editServerInfo({...value}).then((response) => {
      const { code } = response;
      if (code === 200) {
        getUserInfo(token);
        setError('success');
        setVisible(true);
      } else {
        setError('error');
        setVisible(true);
      }
    });
  }

  useEffect(() => {
    fetchData();
    return () => {
      setDataSource([]);
      setFilteredVal([]);
      setValue("");
    };
  }, []);

  return (
    <div>
      {filteredVal?.map((item, index) => {
        return (
          <RadioFormGroup
            key={index}
            dataSource={item}
            name="server"
            onChange={handleChange}
          />
        );
      })}
      <CustomAlertTool visible={visible} setVisible={setVisible} onError={error} />
      <CustomConfirmButton dataSource={value} onOk={submitChange} buttonText={'提交'} cancelText={'取消'} okText={'确认提交'} okMessage={'确认修改为' + value?.name} title={'修改线路'}  />
    </div>
  );
};

export default connect((state) => state.auth, { getUserInfo })(Server);