import Dashboard from '../pages/dashboard';
import Server from '../pages/server';
import Download from '../pages/download';
import Course from '../pages/course';
import About from '../pages/about';
import Profile from '../pages/profile';

const routeModules = [
    { path: "/dashboard", component: <Dashboard />, roles: ["admin", "dev", "user"] },
    { path: "/user/server", component: <Server />, roles: ["admin", "dev", "user"] },
    { path: "/user/download", component: <Download />, roles: ["admin", "dev", "user"] },
    { path: "/user/docs", component: <Course />, roles: ["admin", "dev", "user"] },
    { path: "/user/about", component: <About />, roles: ["admin", "dev", "user"] },
    { path: "/user/profile", component: <Profile />, roles: ["admin", "dev", "user"] },
];

export default routeModules;