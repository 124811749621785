import request from '../utils/request'

export function reqUserInfo(data) {
  return request({
    url: '/user',
    method: 'post',
    data
  })
}

export function reqServerInfo(name) {
  return request({
    url: '/server',
    method: 'get'
  })
}

export function editPassword(data) {
  return request({
    url: '/sub/team/edit/password',
    method: 'post',
    data: data
  })
}

export function editServerInfo(data) {
  return request({
    url: '/sub/team/edit/server',
    method: 'post',
    data
  })
}