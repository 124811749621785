import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { getUserInfo, login } from "../../store/actions";
import logo from "../../assets/leaf-96px.png";
import "./index.css";

const errorsMessage = {
	parent: {
		required: { color: "error", text: "请填写主账号" },
		minLength: {
			color: "error",
			text: "6~18个字符，可使用字母、数字、下划线, 手机号码, 邮箱地址",
		},
		maxLength: {
			color: "error",
			text: "6~18个字符，可使用字母、数字、下划线, 手机号码, 邮箱地址",
		},
		invalid: { color: "error", text: "无效数据，请重新输入" },
	},
	username: {
		required: { color: "error", text: "请填写子账号" },
		minLength: {
			color: "error",
			text: "6~18个字符，可使用字母、数字、下划线, 手机号码, 邮箱地址",
		},
		maxLength: {
			color: "error",
			text: "6~18个字符，可使用字母、数字、下划线, 手机号码, 邮箱地址",
		},
		invalid: { color: "error", text: "无效数据，请重新输入" },
	},
	password: {
		required: { color: "error", text: "请填写密码" },
		minLength: {
			color: "error",
			text: "密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成",
		},
		maxLength: {
			color: "error",
			text: "密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成",
		},
		invalid: { color: "error", text: "无效数据，请重新输入" },
		low: { color: "success", text: "密码强度: 低" },
		middle: { color: "success", text: "密码强度: 中" },
		high: { color: "success", text: "密码强度: 高" },
	},
};

const InputField = ({
	icon,
	type = "text",
	placeholder = "",
	autoComplete = "off",
	onChange,
	onBlur,
}) => {
	const [visible, setVisibie] = useState(false);
	return (
		<div
			className={
				type === "password" ? "input-field password" : "input-field"
			}
		>
			<i className="input-label">
				<i className={icon}></i>
			</i>
			{type === "password" ? (
				<button
					className="input-password"
					onClick={() => {
						setVisibie(!visible);
					}}
				>
					<i className={`fa ${visible ? "fa-eye" : "fa-eye-slash"}`}></i>
				</button>
			) : (
				""
			)}
			<input
				type={
					type !== "password" ? type : visible ? "text" : "password"
				}
				placeholder={placeholder}
				autoComplete={autoComplete}
				onChange={onChange}
				onBlur={onBlur}
			/>
		</div>
	);
};

const TextAlert = ({ color = "error", text = "" }) => {
	const styles = {
		display: text !== "" ? "block" : "none",
		marginTop: "10px",
	};

	return (
		<div style={styles}>
			<Alert severity={color}>{text}</Alert>
		</div>
	);
};

const InputAlert = ({ color = "error", text = "" }) => {
	const styles = {
		display: text !== "" ? "inline-flex" : "none",
	};

	return (
		<div
			className={
				color !== "error"
					? "input-alert input-alert-success"
					: "input-alert input-alert-error"
			}
			style={styles}
		>
			<i className="fa fa-exclamation-circle"></i>
			<span>{text}</span>
		</div>
	);
};

const InputFieldGroup = ({
	icon,
	onAlert = {},
	type = "text",
	placeholder = "",
	autoComplete = "off",
	onChange,
	onBlur,
}) => {
	return (
		<>
			<InputField
				icon={icon}
				type={type}
				placeholder={placeholder}
				autoComplete={autoComplete}
				onChange={onChange}
				onBlur={onBlur}
			/>
			<InputAlert {...onAlert} />
		</>
	);
};

const SignInFormField = ({ login }) => {
	const [values, setValues] = useState({});
	const [errors, setErrors] = useState({});
	const [alert, setAlert] = useState({});

	const handleChange = (field) => (event) => {
		setErrors({});
		setAlert({});
		setValues((pre) => {
			return { ...pre, [field]: event.target.value };
		});
	};

	const handleSignIn = () => {
		let isValid = false;
		const fieldForm = ["parent", "username", "password"];
		const validateForm = [validateParent, validateUsername, validatePassword];
		for (let i in fieldForm) {
			const hasError = validateForm[i](values?.[fieldForm[i]]);
			setErrors((pre) => {
				return {
					...pre,
					[fieldForm[i]]: hasError
						? errorsMessage?.[fieldForm[i]]?.[hasError]
						: "",
				};
			});
			if (!!hasError) {
				isValid = true;
			}
		}
		if (isValid) {
			return;
		}
		login({ ...values })
			.then((repsonse) => {
				setAlert({ color: "success", text: "登录成功" });
			})
			.catch((error) => {
				setAlert({ color: "error", text: "登录失败" });
			});
	};

	const validateParent = (value) => {
		if (!value || value === "" || value === "undefined") {
			return "required";
		}
		if (value.length < 5) {
			return "minLength";
		}
		if (value.length > 25) {
			return "maxLength";
		}
		return "";
	};

	const validateUsername = (value) => {
		if (!value || value === "" || value === "undefined") {
			return "required";
		}
		if (value.length < 4) {
			return "minLength";
		}
		if (value.length > 25) {
			return "maxLength";
		}
		return "";
	};

	const validatePassword = (value) => {
		if (!value || value === "" || value === "undefined") {
			return "required";
		}
		if (value.length < 6) {
			return "minLength";
		}
		if (value.length > 20) {
			return "maxLength";
		}

		return "";
	};

	const validateField = (fieldName) => (event) => {
		let hasError = "";
		setValues((pre) => {
			return { ...pre, [fieldName]: event.target.value };
		});
		switch (fieldName) {
			case "parent":
				hasError = validateUsername(values?.[fieldName]);
				setErrors((pre) => {
					return {
						...pre,
						[fieldName]: hasError
							? errorsMessage?.[fieldName]?.[hasError]
							: "",
					};
				});
				break;
			case "username":
				hasError = validateUsername(values?.[fieldName]);
				setErrors((pre) => {
					return {
						...pre,
						[fieldName]: hasError
							? errorsMessage?.[fieldName]?.[hasError]
							: "",
					};
				});
				break;
			case "password":
				hasError = validatePassword(values?.[fieldName]);
				setErrors((pre) => {
					return {
						...pre,
						[fieldName]: hasError
							? errorsMessage?.[fieldName]?.[hasError]
							: "",
					};
				});
				break;
			default:
				break;
		}
	};

	return (
		<div className="form-container sign-in-container">
			<div className="form-layout">
				<h1 className="title">登录</h1>
				<InputFieldGroup
					onAlert={errors?.["parent"]}
					icon={"fa-solid fa-user"}
					placeholder="主账号"
					onChange={handleChange("parent")}
					onBlur={validateField("parent")}
				/>
				<InputFieldGroup
					onAlert={errors?.["username"]}
					icon={"fa-solid fa-user"}
					placeholder="子账号"
					onChange={handleChange("username")}
					onBlur={validateField("username")}
				/>
				<InputFieldGroup
					onAlert={errors?.["password"]}
					icon={"fa-solid fa-lock"}
					type="password"
					placeholder="密码"
					onChange={handleChange("password")}
					onBlur={validateField("password")}
				/>
				<div className="input-checkbox">
					<input
						type="checkbox"
						name="checkbox"
						className="check-box"
					/>
					<label htmlFor="checkbox">记住密码</label>
				</div>
				<button className="form-layout-button" onClick={handleSignIn}>
					登录
				</button>
				<TextAlert {...alert} />
			</div>
		</div>
	);
};

const Logo = () => {
	return (
		<div className="logo-layout">
			<a href="/" className="logo">
				<img src={logo} alt="reber" />
				reber
			</a>
		</div>
	);
};

const Sign = ({ token, isAuthenticated, login, register, getUserInfo }) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (token) {
			getUserInfo(token);
		}
		if (isAuthenticated) {
			navigate("/dashboard");
		}
	});

	return (
		<div className="sign-layout">
			<Logo />
			<div>
				<SignInFormField login={login} />
			</div>
		</div>
	);
};

export default connect((state) => state.auth, { login, getUserInfo })(
	Sign
);
