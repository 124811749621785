import React from "react";
import "./index.css";

const socialIndex = [
  {
    href: "skype:live:cootos_2?chat",
    tag: "cootos@outlook.com",
    title: "Skype",
    icon: "fa-brands fa-skype",
    color: "skype",
  },
  {
    href: "https://api.whatsapp.com/send?phone=12343390269",
    tag: "+1 234 339 0269",
    title: "WhatsApp",
    icon: "fa-brands fa-whatsapp",
    color: "whatsapp",
  },
  {
    href: "https://t.me/cootos",
    tag: "@cootos",
    title: "Telegram",
    icon: "fa-brands fa-telegram",
    color: "telegram",
  },
  {
    href: "mailto:cootos@outlook.com",
    tag: "cootos@outlook.com",
    title: "Email",
    icon: "fa-solid fa-envelope",
    color: "email",
  },
];

const AboutForm = ({ icon, color, title, tag, href }) => {
  return (
    <>
      <div className="layout-about-box">
        <i className={`${icon} ${color}`}></i>
        <div className="title">{title}</div>
        <div className="tag">{tag}</div>
        <a target="_blank" href={href} rel="noopener noreferrer">
          发送消息
        </a>
      </div>
    </>
  );
};

const About = () => {
  return (
    <>
      <div className="layout-about-container">
        {socialIndex?.map((item, index) => {
          return (
            <AboutForm
              key={index}
              icon={item.icon}
              title={item.title}
              href={item.href}
              color={item.color}
              tag={item.tag}
            />
          );
        })}
      </div>
    </>
  );
};

export default About;
