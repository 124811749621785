import {
    USER_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    SET_USER_TOKEN,
    SET_USER_INFO,
    RESET_USER
} from '../types';
import { getToken } from '../../utils/auth';

const initialState = {
    token: getToken(),
    isAuthenticated: false,
    isLoading: false,
    user: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: {...action.payload},
            };
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
        case SET_USER_TOKEN:
        case SET_USER_INFO:
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                isLoading: false,
            };
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case REGISTER_FAIL:
        case RESET_USER:
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false
            };
        default:
            return state;
    }
}

export default authReducer;