import * as types from "../types";
import { setToken, removeToken } from '../../utils/auth';
import { reqUserInfo } from "../../api/user";

export const getUserInfo = (token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        reqUserInfo(token).then((response) => {
            const { code, message } = response;
            if (code === 200) {
                const { data } = response;
                const { accessToken } = data;
                dispatch(setUserInfo({ token: accessToken, user: { ...data } }));
                setToken(accessToken);
                resolve(data);
            } else if (code === 401) {
                dispatch(resetUser());
                removeToken();
                resolve(response);
            } else {
                reject(message);
            }
        }).catch((error) => {
            reject(error);
        })
    })
}

export const setUserToken = (token) => {
    return {
        type: types.SET_USER_TOKEN,
        token,
    };
};

export const setUserInfo = (payload) => {
    return {
        type: types.SET_USER_INFO,
        payload,
    };
};

export const resetUser = () => {
    return {
        type: types.RESET_USER,
    };
};