import React, { useState } from 'react';
import { connect } from "react-redux";
import './index.css';

const GetRole = (role) => {
  switch (role) {
    case "business":
      return '企业用户';
    case "individual":
      return "个人用户";
    case "family":
      return '家庭用户';
    case "develop":
      return "开发人员";
    case "agent":
      return "代理商";
    case "admin":
      return "管理员";
    default:
      return "个人用户";
  }
}

const Dashboard = ({ user }) => {
  const subscribe = function (code) {
    return "https://rebercloud.com/sub/team/subscribe/" + code;
  }(user?.subscribe_code);
  const shadowrocket = function (code, username, expires) {
    return "sub://" + window.btoa("https://rebercloud.com/sub/team/subscribe/" + code + "#REBER CLOUD | " + username + " | Expires " + expires);
  }(user?.subscribe_code, user?.username, user?.expires);
  const [values, setValues] = useState({ ios: false, android: false });
  const handleChange = (field, text) => {
    const textField = window.document.createElement('textarea');
    textField.innerText = text;
    window.document.body.appendChild(textField);
    textField.focus();
    textField.select();
    setValues((pre) => {
      return { ...pre, [field]: true };
    });
    window.document.execCommand('copy');
    textField.remove();
    setTimeout(() => {
      setValues((pre) => {
        return { ...pre, [field]: false };
      });
    }, 1000);
  };
  return (
    <>
      <div className="layout-dashboard">
        <ul>
          <li>
            <i>用户名</i>
            <span>{user?.username}</span>
          </li>
          <li>
            <i>用户类型</i>
            <span>{GetRole(user?.role)}</span>
          </li>
          <li>
            <i>到期时间</i>
            <span>{user?.expires}</span>
          </li>
          <li>
            <i>当前节点</i>
            <span>{user?.server_name}</span>
          </li>
          <li>
            <i>推荐码</i>
            <span>{user?.referrer_code}</span>
          </li>
          <li>
            <i>IOS</i>
            <button onClick={() => handleChange('ios', shadowrocket)}>{`${values?.['ios'] ? "Copied" : "Click to copy"}`}</button>
          </li>
          <li>
            <i>Android</i>
            <button onClick={() => handleChange('android', subscribe)}>{`${values?.['android'] ? "Copied" : "Click to copy"}`}</button>
          </li>
        </ul>
      </div>
    </>
  )
}

export default connect((state) => state.auth)(Dashboard);