import React from 'react';
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Sign from '../pages/sign';
import Layout from '../pages/layout';
import ProtectedRoute from './protected.route';

const Router = () => {
    return (
        <Routes>
            <Route path="/sign" element={<Sign />} />
            <Route path="/" element={<ProtectedRoute />}>
                <Route path="/*" element={<Layout />} />
            </Route>
        </Routes>
    )
}

export default connect((state) => state.auth)(Router);